// Polyfill for TextEncoder som brukes av react-dom/server i React 18
import 'fastestsmallesttextencoderdecoder/EncoderDecoderTogether.min';

// All JavaScript in here will be loaded server-side
import React from 'react';

import DOMPurify from 'dompurify';
import ReactDOM from 'react-dom';
import ReactDOMClient from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';

// Expose components globally so ReactJS.NET can use them
globalThis.React = React;
globalThis.ReactDOM = ReactDOM;
globalThis.ReactDOMClient = ReactDOMClient;
globalThis.ReactDOMServer = ReactDOMServer;
globalThis.DOMPurify = DOMPurify;
